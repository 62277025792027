<template>
  <div class="news">
    <head-nav></head-nav>
    <about-img></about-img>

    <div class="news-box">
      <h3 class="news-box-title">
        <img
          :src="$t('about.title-8')"
          :class="$i18n.locale=='en'?'news-box-title-img':''"
          alt=""
          :style="$i18n.locale==='en'?'width:300px':''"
        >
      </h3>
      <div class="news-box-supplier">
        <div
          class="news-box-supplier-content"
          v-html="listcontent[0].content"
        >
        </div>

      </div>

      <h3 class="news-box-title">
        <img
          :src="$t('about.title-9')"
          :class="$i18n.locale=='en'?'news-box-title-img':''"
          alt=""
          :style="$i18n.locale==='en'?'width:300px':''"
        >
      </h3>
      <div class="news-box-partner">
        <div
          class="news-box-partner-list"
          v-for="(item, index) in listimg"
          :key="index"
        >
          <img
            :src="item.image.publicUrl"
            alt=""
          >
        </div>
      </div>

    </div>
    <footers></footers>

  </div>
</template>

<script>
import gql from 'graphql-tag';

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  apollo: {
    listcontent: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listcontent: allSupplierCooperations {
                content
              }
            }
          `;
        }
        return gql`
          query {
            listcontent: allEnSupplierCooperations {
              content
            }
          }
        `;
      },
    },
    listimg: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listimg: allCooperativePartners {
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            listimg: allEnCooperativePartners {
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      listcontent: [
        {
          id: 1,
          content: '',
        },
      ],
      listimg: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.news {
  background: #f8f8f8;
  .news-box {
    margin: 0 30px;

    &-title {
      text-align: center;
      width: 100%;
      height: 140px;
      text-align: center;
      padding: 50px 0 30px 0;

      &-img {
        width: 340px;
        height: 68px !important;
      }

      img {
        height: 64px;
      }
    }

    .news-box-supplier {
      margin: 0 30px;

      &-content {
        font-size: 28px;
        line-height: 40px;
      }

      ::v-deep p {
        line-height: 40px;
        background: #f8f8f8;
        padding-left: 4px;
        color: #555;
        font-size: 24px;
      }

      ::v-deep table {
        border: 1px solid #eeeeee;
        width: 100% !important;

        tbody {
          tr {
            background: #f8f8f8;
            line-height: 35px;

            td {
              padding-left: 4px;
              color: #666;
            }
          }

          tr:nth-child(2n + 2) {
            background: #fff;
          }
        }
      }
    }

    .news-box-partner {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 80px;

      &-list {
        width: 330px;
        height: 280px;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .news .news-box .news-box-partner-list {
    width: 280px;
  }
}
</style>
