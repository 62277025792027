<template>
  <div class="about-img">
    <img
      v-for="(item, index) in imgs"
      :key="index"
      :src="item.imgs"
      alt=""
    >
  </div>
</template>

<script>
import imgs1 from '@/assets/image/about/banner.png';

export default {
  data() {
    return {
      imgs: [
        {
          id: 1,
          imgs: imgs1,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-img {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
